import { Button, Grid, Group, Loader, PinInput } from '@mantine/core';
import { useState } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { apiBulkTransfer } from '../../../../../api/ApiServices';
import { notifications } from '@mantine/notifications';

type Prop = {
  onNext: () => void;
  onPrev: () => void;
  beneficiaries: Beneficiary[];
};

const Step3 = ({ onPrev, onNext, beneficiaries }: Prop) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    pin: yup.string().required('Pin is required').length(6, 'Pin must be 6 digits'),
  });

  const form = useForm({
    initialValues: {
      pin: '',
    },
    validate: yupResolver(schema),
  });

  // verify pin // handle Bulk Transfer
  const handleBulkTransfer = async (values: { pin: string }) => {
    try {
      setLoading(true);
      const payload = {
        pin: values.pin,
        originatorAccountNumber: beneficiaries[0].originatorAccountNumber,
        beneficiaries: beneficiaries.map((beneficiary) => ({
          accountName: beneficiary.accountName,
          accountNumber: beneficiary.accountNumber.toString(),
          amount: beneficiary.amount,
          bankName: beneficiary.bankName,
          category: beneficiary.category,
          narration: beneficiary.narration,
          nameEnquiryRef: beneficiary.nameEnquiryRef,
          bankCode: beneficiary.bankCode.toString(),
        })),
      };

      const resp = await apiBulkTransfer(payload);
      console.log(resp);
      onNext();
      setLoading(false);
    } catch (error: any) {
      setError(
        notifications.show({
          title: error.response?.data?.message || 'Something went wrong',
          color: 'red',
          message: error.message || 'An error occurred, please try again later.',
        })
      );
      form.setFieldValue('pin', '');

      setLoading(false);
    }
  };

  return (
    <>
      <section>
        <div className="text-center w-2/3 mx-auto mt-10">
          <h1>
            Enter your 2 factor authentication code to send <br /> the transaction for approval
          </h1>
          <form onSubmit={form.onSubmit(handleBulkTransfer)} className="mx-auto text-center">
            <div className="my-5 flex justify-center">
              <PinInput
                length={6}
                className="w-2/3 mx-auto text-center"
                type="number"
                size="md"
                {...form.getInputProps('pin')}
              />
            </div>

            <Grid className="mt-12 mb-10">
              <Grid.Col span={6}>
                <Button variant="outline" fullWidth radius={12} type="button" onClick={onPrev}>
                  Back
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button
                  variant="gradient"
                  gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                  fullWidth
                  radius={12}
                  type="submit"
                  disabled={loading}>
                  {loading ? <Loader size={20} /> : 'Send'}
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </section>
    </>
  );
};

export default Step3;
