import React, { useEffect, useRef, useState } from 'react';
import { Dropzone } from '@mantine/dropzone';
import { Button, Grid, Group, Select, Text } from '@mantine/core';
import '@mantine/dropzone/styles.css';
import { yupResolver } from '@mantine/form';
import { useForm } from '@mantine/form';
import * as yup from 'yup';
import { MIME_TYPES } from '@mantine/dropzone';
import { FcCancel } from 'react-icons/fc';
import { IoCloudDoneOutline } from 'react-icons/io5';
import { IoMdClose, IoMdDownload } from 'react-icons/io';
import * as Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { apiAllAccount, apiGetAccountName, apiGetCategories } from '../../../../../api/ApiServices';
import AddbeneficiariesTable from '../AddBeneficiariesTable';
import { notifications } from '@mantine/notifications';
import { getCBNTransferFee } from '../modal/Step2';
import numeral from 'numeral';
import { MAX_TRANSFER_AMOUNT } from '../../constant';

type Prop = {
  onNext: (beneficiaries: Beneficiary[]) => void;
  onStepChange: (step: number, title: string) => void;
  beneficiaries: Beneficiary[];
};

const validateEnrichData = (data: any[]) => {
  const validatedData: Beneficiary[] = [];
  let i = 1;
  try {
    for (const datum of data) {
      const obj = {} as Beneficiary;
      i++;

      if (!datum.bankName) {
        throw `Please provide bank name on row ${i}`;
      } else {
        obj.bankName = datum.bankName;
      }

      if (!datum.bankCode) {
        throw `Please provide bank code on row ${i}`;
      } else {
        obj.bankCode = String(datum.bankCode).replace('"', '').replace("'", '').trim();
      }

      if (!datum.originatorAccountNumber) {
        throw 'Please provide originator account number';
      } else {
        obj.originatorAccountNumber = datum.originatorAccountNumber;
      }

      if (!datum.accountNumber) {
        throw `Please provide an account number on row ${i}`;
      } else {
        let accountNumber = String(datum.accountNumber).replace('"', '').replace("'", '').trim();

        if (accountNumber.length !== 10) throw `Please provide a valid account number on row ${i}`;

        obj.accountNumber = accountNumber;
      }

      if (typeof datum.amount !== 'number') {
        throw `Please provide valid amount on row ${i}`;
      } else {
        if (datum.amount > MAX_TRANSFER_AMOUNT)
          throw `Exceeded transfer amount limit of ₦${numeral(MAX_TRANSFER_AMOUNT).format(
            '0,0.00'
          )}. Please reduce the amount and try again on row ${i}`;

        obj.amount = String(datum.amount);
      }

      obj.narration = datum?.narration || '';
      obj.category = datum?.category || '';

      validatedData.push(obj);
    }

    return validatedData;
  } catch (error) {
    notifications.show({
      title: 'Invalid CSV',
      color: 'cyan',
      message: JSON.stringify(error) ?? 'Please use upload template as a guideline',
    });
    throw error;
  }
};

const CsvStep1 = ({ onNext, onStepChange, beneficiaries }: Prop) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [allAccount, setAllAccount] = useState<Account[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [isLoadingAccNames, setIsLoadingAccNames] = useState(false);

  useEffect(() => {
    if (files?.length > 0) handleNext(false);
  }, [files]);

  const [localbeneficiaries, setLocalbeneficiaries] = useState<Beneficiary[]>(beneficiaries);

  useEffect(() => {
    if (localbeneficiaries?.length > 0) checkAccountName([...localbeneficiaries]);
  }, [localbeneficiaries]);

  const fetchAccountName = async (bankCode: string, accountNumber: string) => {
    try {
      const payload = {
        bankCode: bankCode,
        accountNumber: accountNumber,
      };
      const resp = await apiGetAccountName(payload);

      return { accountName: resp.data.accountName, enqRef: resp.data.nameEnquiryRef };
    } catch (error) {
      throw error;
    }
  };

  const checkAccountName = async (beneficiaries?: Array<Beneficiary>) => {
    try {
      if (beneficiaries && isLoadingAccNames === false) {
        setIsLoadingAccNames(true);
        let isAccNameChecked = false;
        let idx = 0;
        for (const beneficiary of beneficiaries) {
          idx++;
          if (beneficiary.accountName) continue;
          try {
            notifications.show({
              title: 'Loading Account Name',
              color: 'cyan',
              message: `Fetching Account Name for ${beneficiary.accountNumber}`,
            });
            const { accountName, enqRef } = await fetchAccountName(
              beneficiary.bankCode,
              String(beneficiary.accountNumber)
            );
            beneficiary.accountName = accountName;
            beneficiary.nameEnquiryRef = enqRef;
            beneficiary.fee = getCBNTransferFee(beneficiary.amount, beneficiary.bankCode);
            isAccNameChecked = true;
          } catch (error: any) {
            setFiles([]);
            setError(
              `Could not Fetch Account Name for ${beneficiary.accountNumber} on row: ${
                idx + 1
              }, Please upload file`
            );
            notifications.show({
              title: 'Name Enquiry',
              color: 'red',
              message:
                error?.message ||
                `Could not Fetch Account Name for ${beneficiary.accountNumber} on row: ${idx + 1}`,
            });
          }
        }
        if (isAccNameChecked) setLocalbeneficiaries(beneficiaries);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingAccNames(false);
    }
  };

  const openRef = useRef<() => void>(null);

  const schema = yup.object().shape({
    category: yup.string().optional().nullable(),
    originatorAccountNumber: yup.string().required('Business account is required'),
  });

  const form = useForm({
    initialValues: {
      category: null,
      originatorAccountNumber: '',
    },
    validate: yupResolver(schema),
  });

  const handleNext = async (shouldNext = true) => {
    if (files.length === 0) {
      setError('Please upload a file');
      return;
    }

    const category = form.values.category;
    const originatorAccountNumber = form.values.originatorAccountNumber;

    const file = files[0];

    try {
      const data = await readFile(file);

      let enrichedData = data.map((row) => ({
        ...row,
        amount: row['AMOUNT'],
        bankName: row['BENEFICIARY BANK NAME'],
        bankCode: row['BANK CODE'],
        accountNumber: row['BENEFICIARY ACCOUNT NUMBER'],
        narration: row['NARRATION'],
        category,
        originatorAccountNumber,
      }));

      // get only the ones with the at least 1 field...
      enrichedData = enrichedData.filter((datum) => {
        return (
          datum.amount || datum.accountNumber || datum.bankName || datum.bankCode || datum.narration
        );
      });

      let validatedEnrichData = validateEnrichData(enrichedData);

      if (typeof validatedEnrichData === 'undefined') {
        validatedEnrichData = [];
      }

      setError(null);

      if (shouldNext) onNext(localbeneficiaries);
      else setLocalbeneficiaries(validatedEnrichData);
    } catch (err) {
      setError('Failed to process the file');
      console.error(err);
    }
  };

  const readFile = (file: File): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event.target?.result;

        if (file.type === MIME_TYPES.csv) {
          Papa.parse(result as string, {
            header: true,
            complete: (results) => {
              resolve(results.data);
            },
            error: (error: any) => {
              reject(error);
            },
          });
        } else if (file.type === MIME_TYPES.xlsx) {
          const workbook = XLSX.read(result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet);
          resolve(data);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      if (file.type === MIME_TYPES.csv) {
        reader.readAsText(file);
      } else if (file.type === MIME_TYPES.xlsx) {
        reader.readAsArrayBuffer(file);
      }
    });
  };

  // remove beneficiaries
  const removeBeneficiary = (index: number) => {
    const beneficiariesDetails = [...localbeneficiaries];
    beneficiariesDetails.splice(index, 1);
    setLocalbeneficiaries(beneficiariesDetails);
  };

  // get all business accounts
  const getAllAccount = async () => {
    try {
      const response = await apiAllAccount();
      setAllAccount(response.data.accounts);
      if (response.data.accounts) {
        form.setValues({ originatorAccountNumber: response.data.accounts[0]?.number });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAccount();
    fetchCategories();
  }, []);

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const resp = await apiGetCategories();
      setCategories(resp.data.categories);
    } catch (error) {
      setError('Failed to fetch categories');
      console.error(error);
    }
  };

  const handleRemoveFile = () => {
    setFiles([]);
  };

  return (
    <section className="my-0 p-10">
      <Group justify="right" my={2}>
        <a href="/assets/UploadTemplate.xlsx" download="BulkTransferTemplate.xlsx">
          <Button variant="filled" w={200} radius={12} mx={10} type="button">
            <IoMdDownload /> <span className="ml-2">Download Template</span>
          </Button>
        </a>
      </Group>
      <form>
        <Grid mt={20} mb={30} grow>
          <Grid.Col span={6}>
            <Select
              ta="left"
              my={8}
              searchable
              label="Select account"
              withAsterisk
              type="text"
              placeholder="Pick value"
              data={allAccount.map((account) => ({
                value: account.number,
                label: `${account.name} (${account.number})`,
              }))}
              {...form.getInputProps('originatorAccountNumber')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              ta="left"
              my={8}
              searchable
              label="Payment category"
              placeholder="Pick value"
              data={categories.map((category) => ({
                value: category.name,
                label: category.name,
              }))}
              {...form.getInputProps('category')}
            />
          </Grid.Col>
        </Grid>

        <Dropzone
          bg="#F6F6F6"
          onDrop={(acceptedFiles: React.SetStateAction<File[]>) => setFiles(acceptedFiles)}
          onReject={(rejectedFiles: any) => console.log('rejected files', rejectedFiles)}
          maxSize={5 * 1024 ** 2}
          accept={[MIME_TYPES.csv, MIME_TYPES.xlsx]}
          openRef={openRef}>
          {files.length === 0 ? (
            <Group justify="center" gap="xl" mih={80} style={{ pointerEvents: 'none' }}>
              <Dropzone.Accept>
                <IoCloudDoneOutline size={26} />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <FcCancel size={26} />
              </Dropzone.Reject>

              <div className="text- flex gap-6">
                <p className="text-sm">Drag and drop your document here</p>
                <small className="mt-[0.1rem]">or</small>
                <button
                  type="button"
                  className="px-4 py-1 border border-[#0798D0] rounded-full text-xs text-[#0798D0]"
                  // onClick={() => openRef.current?.()}
                  style={{ pointerEvents: 'all' }}>
                  BROWSE
                </button>
              </div>
            </Group>
          ) : (
            <div className="grid grid-cols-1 py-6">
              {files.map((file, index) => (
                <Text key={index} size="sm">
                  {file.name}
                </Text>
              ))}

              <p className=" col-end-11 gap-10 " onClick={() => handleRemoveFile()}>
                <IoMdClose />
              </p>
            </div>
          )}
        </Dropzone>
        <div className="flex flex-col justify-center items-center">
          <h6 className="text-center font-medium text-sm my-3">File size limit details</h6>
          {error && <p className=" text-sm font-semibold text-red-500">{error}</p>}
        </div>
      </form>
      <AddbeneficiariesTable
        beneficiaries={localbeneficiaries}
        removeBeneficiary={removeBeneficiary}
      />
      <Group justify="right" mt={60}>
        <Button
          variant="gradient"
          gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
          w={150}
          radius={12}
          mx={10}
          type="button"
          onClick={() => handleNext()}>
          Next
        </Button>
      </Group>
    </section>
  );
};

export default CsvStep1;
