import React, { useEffect, useState } from 'react';
import style from '../../styles/style.module.css';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { apiAccount, apiAllAccount, apiSwitchAccount } from '../../../api/ApiServices';
import { Button, Menu } from '@mantine/core';

const Header = () => {
  const [allAccount, setAllAccount] = useState<Account[]>([]);
  const [account, setAccount] = useState<Account>();
  const [selectedAcc, setSelectedAcc] = useState('Select Account');
  const [isLoading, setIsLoading] = useState(false);

  // get all business account
  const getAllAccount = async () => {
    try {
      const response = await apiAllAccount();
      setAllAccount(response.data.accounts);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAccount();
  }, []);

  // get single business account
  const getAccount = async () => {
    try {
      const response = await apiAccount();
      setAccount(response.data.accounts);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // get switch business account

  const getSwitchAccount = async (_id: string, accountName: string) => {
    setIsLoading(true);
    try {
      const response = await apiSwitchAccount(_id);
      await getAccount();
      setSelectedAcc(accountName);
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className={`${style.header} bg-black p-3 h-20 z-20`}>
        <div className="w-[98%] mx-auto text-white grid grid-cols-8">
          <div className="col-span-7">
            <img src="/assets/dash/dash-logo.png" alt="source logo" width={140} height={140} />
          </div>

          <div className="my-auto col-end-11">
            <Menu>
              <Menu.Target>
                <Button
                  p={0}
                  m={0}
                  className="!bg-transparent shadow-lg !text-white "
                  loading={isLoading}>
                  {selectedAcc}
                  {!isLoading && <MdKeyboardArrowDown className="my-auto mx-1" />}
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                {allAccount?.map((accounts: Account) => (
                  <Menu.Item
                    key={accounts._id}
                    onClick={() =>
                      getSwitchAccount(accounts._id, `${accounts.name} (${accounts.number})`)
                    }>
                    {accounts.name} ({accounts.number})
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
