import { Modal } from '@mantine/core';
import { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { notifications } from '@mantine/notifications';
import { apiAcceptApproval, apiDeclineApproval } from '../../../../../api/ApiServices';

type Prop = {
  opened: boolean;
  open: () => void;
  close: () => void;
  resetSteps: () => void;
  approvalData: Transaction[];
  currentStep?: number;
  isApproval: boolean;
  setTab: React.Dispatch<React.SetStateAction<string | null>>;
};

export type StepType = {
  step: number;
  title: string;
};

const Index = ({
  opened,
  close,
  resetSteps,
  approvalData,
  isApproval,
  setTab,
  currentStep = 1,
}: Prop) => {
  const [activeStep, setActiveStep] = useState(currentStep);
  const [isApprove, setIsApprove] = useState<boolean>(!!isApproval);
  const [isLoading, setIsLoading] = useState(false);

  const steps = [
    { step: 1, title: 'Review details of your transfer' },
    { step: 2, title: '' },
    { step: 3, title: '' },
  ];
  const handleApprove = () => {
    setIsApprove(true);
    nextStep();
  };

  const handleReject = () => {
    setIsApprove(false);
    nextStep();
  };

  const handleSend = async (pin: string) => {
    try {
      setIsLoading(true);
      const payload = {
        pin,
        approvals: approvalData.map((transaction: { _id: string }) => ({
          transactionId: transaction._id,
        })),
      };
      console.log('Payload:', payload);

      if (isApprove) {
        await apiAcceptApproval(payload);
      } else {
        await apiDeclineApproval(payload);
      }

      notifications.show({
        title: 'Success',
        color: 'green',
        message: `Transaction ${isApprove ? 'approved' : 'rejected'} successfully!`,
      });
      nextStep();

      setTab(isApproval ? 'approved' : 'rejected');
    } catch (error: any) {
      console.log(error, 'error');
      notifications.show({
        title: error.status || 'Error',
        color: 'red',
        message: error.message || `Failed to ${isApprove ? 'approve' : 'reject'} transaction.`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = () => {
    setActiveStep((current) => {
      if (current === steps.length) {
        // Reset to 0 after the last step
        resetSteps();
        return 1;
      }
      return Math.min(current + 1, steps.length);
    });
  };

  const prevStep = () => setActiveStep((current) => Math.max(current - 1, 1));

  const getCurrentStepTitle = (step: number) => steps.find((s) => s.step === step)?.title || '';

  const handleClose = () => {
    resetSteps();
    setActiveStep(1);
    close();
  };

  return (
    <>
      <Modal
        size={513}
        opened={opened}
        onClose={handleClose}
        title={getCurrentStepTitle(activeStep)}
        closeOnClickOutside={false}
        centered>
        {activeStep === 1 && (
          <Step1 onApprove={handleApprove} onReject={handleReject} approvalData={approvalData} />
        )}
        {activeStep === 2 && (
          <Step2
            onPrev={prevStep}
            onSend={handleSend}
            approvalData={approvalData}
            isApproval={isApprove}
            isLoading={isLoading}
          />
        )}
        {activeStep === 3 && (
          <Step3 isOpen onClose={close} isApproval={isApprove} approvalData={approvalData} />
        )}
      </Modal>
    </>
  );
};

export default Index;
