import {
  TextInput,
  Button,
  Group,
  Card,
  PasswordInput,
  Center,
  Container,
  Anchor,
  BackgroundImage,
  Text,
  Loader,
  PinInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import * as yup from 'yup';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { apiLogin } from '../../api/ApiServices';
import ResetPassword from '../passwordReset/RestPasword';
import { useNavigate } from 'react-router';

export function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [codeRequested, setCodeRequested] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().required('Email address is required').email('Invalid email'),
    password: yup.string().required('Password is required'),
    ...(codeRequested && { code: yup.string().required('Code is required').min(6) }),
  });
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      ...(codeRequested && { code: '' }),
    },

    validate: yupResolver(schema),
  });

  const navigate = useNavigate();
  // login email and password
  const login = async (params: any) => {
    try {
      setIsLoading(true);
      const payload = {
        ...params,
      };

      if (!codeRequested) delete payload.code;

      const resp = await apiLogin(payload);
      setTimeout(() => {
        navigate('/dashboard');
        localStorage.removeItem('passStep');
      }, 1000);
      localStorage.clear();

      const roleName = resp.data.user.role.name;

      if (roleName) {
        localStorage.setItem('roleName', roleName);
      }
      setIsLoading(false);
    } catch (error: any) {
      form.setFieldValue('code', '');
      
      if (error?.data?.meta?.codeRequested) {
        setCodeRequested(true);
      } else
        setError(
          notifications.show({
            title: 'Something went wrong',
            color: 'red',
            message: error?.message || 'An error occurred, please try again later.',
          })
        );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePinComplete = (value: string) => {
    form.setFieldValue('code', value);
    const validationResult = form.validateField('code');
    if (!validationResult.hasError) {
      login(form.values);
    }
  };

  return (
    <Container px={0}>
      <Card shadow="md" padding="xl" radius="md" maw={450} mx="auto">
        {codeRequested ? (
          <>
            <div className="text-center">
              <h1 className="font-medium text-xl mb-5">Log in with OTP</h1>
              <small>Enter OTP generated in App</small>
              <form onSubmit={form.onSubmit(login)}>
                <PinInput
                  radius={12}
                  type="number"
                  length={6}
                  my={12}
                  className="w-3/4 mx-auto"
                  onComplete={handlePinComplete}
                  {...form.getInputProps('code')}
                />
                <Group mt="md">
                  <Button
                    variant="gradient"
                    gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                    fullWidth
                    radius={12}
                    // mx={10}
                    h={45}
                    mb={40}
                    mt={20}
                    type="submit"
                    // onClick={loginOtp}
                    disabled={isLoading}>
                    {isLoading ? <Loader size={20} /> : 'Login'}
                  </Button>
                </Group>
              </form>
            </div>
          </>
        ) : (
          <form onSubmit={form.onSubmit(login)}>
            <TextInput
              withAsterisk
              radius={12}
              px={10}
              my={12}
              placeholder="Email address"
              {...form.getInputProps('email')}
            />
            <PasswordInput
              radius={12}
              px={10}
              mt={18}
              placeholder="Password"
              {...form.getInputProps('password')}
            />
            <Group justify="right">
              <Anchor
                left={300}
                href="/forget-password"
                underline="never"
                size="sm"
                mr={20}
                mt={10}
                c={'#0798D0'}>
                Forgot password?
              </Anchor>
            </Group>
            <Group mt="md">
              <Button
                variant="gradient"
                gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                fullWidth
                radius={12}
                mx={10}
                h={45}
                mb={40}
                mt={20}
                type="submit"
                disabled={isLoading}>
                {isLoading ? <Loader size={20} /> : 'Login'}
              </Button>
            </Group>
          </form>
        )}
        <Card.Section mx={-60}>
          <BackgroundImage src="/assets/form-bg.png">
            <Center p="md">
              <Anchor href="/business" underline="never" size="sm" c="white">
                Don’t have an account?
                <Text span mx={5} inherit fw={500}>
                  Open my account
                </Text>
              </Anchor>
            </Center>
          </BackgroundImage>
        </Card.Section>
      </Card>
    </Container>
  );
}
