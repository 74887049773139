import { Anchor, Modal } from '@mantine/core';
import numeral from 'numeral';
import { apiTranReceipt } from '../../../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { saveAs } from 'file-saver';

type Prop = {
  approvalData: Transaction[];
  isOpen: boolean;
  isApproval: boolean;
  onClose: () => void;
};

const Step3 = ({ approvalData, isApproval, isOpen, onClose }: Prop) => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // import { saveAs } from 'file-saver';

  const id = approvalData[0];

const handleDownload = async (id: string) => {
  try {
    console.log('Starting download for ID:', id);

    const response = await apiTranReceipt(id);
    const blob = new Blob([response.data], { type: 'application/pdf' });
    console.log('Blob created', {
      size: blob.size,
      type: blob.type,
    });

    saveAs(blob, `receipt_${id}.pdf`);
    console.log('saveAs called');
  } catch (error: any) {
    console.error('Error in handleDownload:', error);
    notifications.show({
      title: 'Download Failed',
      color: 'red',
      message: error.message || 'An error occurred, please try again later.',
    });
  }
};

  const roleName = localStorage.getItem('roleName');
  return (
    <Modal centered opened={isOpen} onClose={onClose} withCloseButton={false}>
      <section>
        <div className="text-center">
          {isApproval ? (
            roleName === 'checker' ? (
              <section>
                <div className="text-center ">
                  <img
                    src="/assets/success-img.png"
                    alt="success image"
                    className="w-2/4 mx-auto"
                  />
                  <h2 className="my-3 text-[#0798D0] font-bold">Successful</h2>
                  <h2 className="my-3">Transaction is sent for approval</h2>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/dashboard/approvals');
                    }}
                    className="!text-black text-lg !underline mt-6 mb-3 cursor-pointer">
                    Take me to approval
                  </button>
                </div>
              </section>
            ) : (
              <>
                <img src="/assets/success-img2.png" alt="success image" className="w-2/4 mx-auto" />
                <h2 className="my-3 text-[#0798D0] font-bold">Money Sent</h2>
                {approvalData.length === 1 ?  (
                  <h2 className="my-6 text-center mx-auto">
                    You sent{' '}
                    <span className="font-semibold">
                      {numeral(approvalData[0]?.amount || 0).format('0,0.00')} <br /> to{' '}
                      {approvalData[0].beneficiaryAccountName}
                    </span>
                  </h2>
                ): null }
                <div className="grid grid-cols-1 w-2/5 mx-auto my-8">
                  {/* <div className="text-center ">
                    <div className="border border-[#1E253F] border-opacity-40 cursor-pointer rounded-md w-[60px] h-[55px] card shadow-lg mx-auto p-4">
                      <img
                        src="/assets/dash/saved-icon.png"
                        alt="saved icon"
                        width={16}
                        className="text-center mx-auto"
                      />
                    </div>
                    <p className="text-xs mt-2"> Save Beneficiary</p>
                  </div>

                  <div className="text-center ">
                    <div className="border border-[#1E253F] border-opacity-40 cursor-pointer rounded-md w-[60px] h-[55px] card shadow-lg mx-auto p-4">
                      <img
                        src="/assets/dash/share-icon.png"
                        alt="share icon"
                        width={22}
                        className="text-center mx-auto"
                      />
                    </div>
                    <p className="text-xs mt-2">
                      {' '}
                      Share <br /> Receipt
                    </p>
                  </div> */}

                  <div className="text-center ">
                    <div
                      className="border border-[#1E253F] border-opacity-40 cursor-pointer rounded-md w-[60px] h-[55px] card shadow-lg mx-auto  p-4"
                      onClick={() => handleDownload(approvalData[0]._id)}>
                      <img
                        src="/assets/dash/download.png"
                        alt="download icon"
                        width={22}
                        className="text-center mx-auto"
                      />
                    </div>
                    <p className="text-xs mt-2"> Download Receipt</p>
                  </div>
                </div>
              </>
            )
          ) : (
            <>
              <img
                src="/assets/decline-transaction.png"
                alt="decline image"
                className="w-4/5 mx-auto my-10"
              />
              <h2 className="my- text-red-500 font-bold my-4">Transaction Declined</h2>
              <p className=" font-medium mb-6"> This transaction has been declined</p>
            </>
          )}
          <button
            onClick={() => {
              navigate('/dashboard');
            }}
            className="!text-black text-lg !underline mt-6 mb-3 cursor-pointer">
            Take me home
          </button>
        </div>
      </section>
    </Modal>
  );
};

export default Step3;
