import React, { useEffect, useRef, useState } from 'react';
import { BackgroundImage, Center, Loader } from '@mantine/core';
import style from './styles/style.module.css';
import { IoIosTrendingUp, IoIosTrendingDown } from 'react-icons/io';
import { BarChart } from '@mantine/charts';
import Balance, { DateRange, DateRangeRef } from './component/common/Balance';
import { apiGetStats } from '../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { Navigate, useNavigate } from 'react-router';

const Dashboard = () => {
  const dateRangeRef = useRef<DateRangeRef>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [stats, setStats] = useState<DashboardStat | null>(null);
  const [dataChart, setDataChart] = useState<Transaction[]>([]);
  const [error, setError] = useState<string>('');

  const navigate = useNavigate();

  const getStats = async (startDate?: string, endDate?: string) => {
    try {
      setLoading(true);
      const response = await apiGetStats(startDate, endDate);
      setStats(response.data.stats);
      setDataChart(response.data.transactions || []);
      setIsEmpty(response.data.transactions.length === 0);
      setLoading(false);
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.message || 'An error occurred, please try again later.',
        })
      );
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  const dateRangeCallback = (dateRange: DateRange) => {
    if (dateRange?.startDate && dateRange?.endDate) {
      const startDateString = dateRange.startDate.toISOString();
      const endDateString = dateRange.endDate.toISOString();
      getStats(startDateString, endDateString);
    } else {
      getStats(); // Fetch data without date range if not available
    }
  };

  return (
    <section className={`${style.main} px-8 pt-5`}>
      {/* first card section */}
      <Balance ref={dateRangeRef} dateRangeCallback={dateRangeCallback} />

      {/* second section */}
      <BackgroundImage
        src="/assets/dash/dashboard-bg.webp"
        className="h-[180px]"
        style={{
          borderRadius: '9px',
        }}>
        <div className="flex gap-5 mt-10 pt-5 justify-center text-center">
          {/* Render stats cards */}
          {[
            {
              title: 'Transactions',
              value: stats?.all.total,
              percentage: stats?.all.percentage,
              onClick: () => navigate('/dashboard/transaction-history#all'),
            },
            {
              title: 'User count',
              value: stats?.signatories.total,
              onClick: () => navigate('/dashboard/settings'),
            },
            {
              title: 'Successful',
              value: stats?.successful?.total,
              percentage: stats?.successful?.percentage,
              onClick: () => navigate('/dashboard/transaction-history#successful'),
            },
            {
              title: 'Failed',
              value: stats?.failed.total,
              percentage: stats?.failed.percentage,
              onClick: () => navigate('/dashboard/transaction-history#failed'),
            },
            {
              title: 'Awaiting Approval',
              value: stats?.pending.total,
              onClick: () => navigate('/dashboard/approvals'),
            },
          ].map(({ title, value, percentage, onClick }, index) => (
            <div
              key={index}
              className="card w-[12rem] h-[91.76px] rounded-lg shadow-lg bg-white p-3 mt-5 cursor-pointer"
              onClick={onClick}>
              <h1 className="mt-3 font-bold text-2xl">{value ?? 0}</h1>
              <div className="flex gap-5 justify-center my-2 text_xxs ">
                <p className="font-bold mt-1">{title}</p>
                {percentage !== undefined && (
                  <div className="flex gap-2">
                    <span className="mt-1">{percentage}</span>
                    {parseInt(percentage) > 0 ? (
                      <IoIosTrendingUp color="green" className="text-sm" />
                    ) : (
                      <IoIosTrendingDown color="red" className="text-sm" />
                    )}{' '}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </BackgroundImage>

      {/* chart section */}
      <section className="card shadow-lg rounded-lg bg-white my-10 p-5 ">
        {!loading && isEmpty ? (
          <div className="text-center ">
            <img
              src="/assets/dash/empty-state.png"
              alt="empty state"
              className="w-[17rem] mx-auto"
            />
            <h2 className="font-semibold mt-1">No Data</h2>
            <p className="text-sm">Currently, there are no recent payment recorded.</p>
          </div>
        ) : loading ? (
          <Center>
            <Loader size={30} className="my-24" />
          </Center>
        ) : (
          <div>
            <h1 className="mt-3 font-medium text-sm">Transactions</h1>
            <BarChart
              h={300}
              data={dataChart}
              pt={30}
              barProps={{
                radius: 8,
                // width: 20
              }}
              dataKey="date"
              series={[{ name: 'totalAmount', color: '#0798D0' }]}
            />
          </div>
        )}
      </section>
    </section>
  );
};

export default Dashboard;
