import React, { useEffect, useState } from 'react';
import style from '../../styles/style.module.css';
import { Button, Grid, NumberInput, Select, TextInput } from '@mantine/core';
import { apiAllAccount, apiStatementReceipt, apiTranReceipt } from '../../../api/ApiServices';
import { yupResolver } from 'mantine-form-yup-resolver';
import { useForm } from '@mantine/form';
import * as yup from 'yup';
import { notifications } from '@mantine/notifications';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';

export enum StatementFormat {
  xlsx = 'xlsx',
  pdf = 'pdf',
  csv = 'csv',
}

export enum StatementFilter {
  Days = 'Last x Days',
  Date = 'Date',
  Transactions = 'Last x Transactions',
}
type StatementFilterKeys = keyof typeof StatementFilter;

const Statement = () => {
  const [allAccount, setAllAccount] = useState<Account[]>([]);
  const [currentAccount, setCurrentAccount] = useState<Account | null>(null);
  const [isBankLoading, setIsBankLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<StatementFilterKeys>('Date');
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    originatorAccountNumber: yup.string().required('Account number is required'),
    filter: yup.string().required('Filter is required'),
    startDate: yup
      .date()
      .when('filter', {
        is: 'Date',
        then: (schema) => schema.required('Start Date is required'),
        otherwise: (schema) => schema.notRequired(),
      })
      .nullable(),
    endDate: yup.date().when('filter', {
      is: 'Date',
      then: (schema) => schema.required('End Date is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    transactions: yup.number().when('filter', {
      is: 'Transactions',
      then: (schema) =>
        schema
          .min(1)
          .required('Number of transactions is required and should be a positive integer'),
      otherwise: (schema) => schema.notRequired(),
    }),
    noOfDays: yup.string().when('filter', {
      is: 'Days',
      then: (schema) => schema.required('Number of days is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    type: yup.string().required('Transaction Type is required'),
    format: yup.string().required('File Format is required'),
  });

  const form = useForm({
    initialValues: {
      originatorAccountNumber: '',
      filter: 'Date',
      startDate: new Date(),
      endDate: new Date(),
      transactions: 0,
      noOfDays: '',
      type: 'All',
      format: StatementFormat.xlsx,
    },
    validate: yupResolver(schema),
  });

  // get all business accounts
  const getAllAccount = async () => {
    setIsBankLoading(true);
    try {
      const response = await apiAllAccount();
      const accs = response.data.accounts;

      setAllAccount(accs);

      const currAcc = accs[0];

      form.setValues({
        originatorAccountNumber: currAcc?.number,
      });

      setCurrentAccount(currAcc);
    } catch (error: any) {
      notifications.show({
        title: error?.status || 'Error',
        message: error?.message || 'Something went wrong',
        color: 'red',
      });
      console.log(error);
    } finally {
      setIsBankLoading(false);
    }
  };

  // No of days options....
  const noOfDaysOption = ['Last 20 days', 'Last 40 days', 'Last 60 days', 'Last 80 days'];

  useEffect(() => {
    getAllAccount();
  }, []);

  useEffect(() => {
    if (selectedFilter) {
      form.setFieldValue('filter', selectedFilter as StatementFilter);
      if (selectedFilter === ('Date' satisfies StatementFilterKeys)) {
        form.setValues({
          startDate: dayjs().startOf('month').toDate(),
          endDate: dayjs().endOf('month').toDate(),
        });
      } else if (selectedFilter === ('Days' satisfies StatementFilterKeys)) {
        form.setFieldValue('noOfDays', 'Last 20 days');
      } else if (selectedFilter === ('Transactions' satisfies StatementFilterKeys)) {
        form.setFieldValue('transactions', 10);
      }
    }
  }, [selectedFilter]);

  const handleDownload = async (values: typeof form.values) => {
    setLoading(true);
    try {
      // Construct the query parameters
      const queryParams = new URLSearchParams({
        filter: values.filter,
        type: values.type === 'All' ? '' : values.type.toLowerCase(),
        format: values.format,
      });

      // Add conditional parameters based on the selected filter
      if (values.filter === 'Date') {
        queryParams.append('startDate', new Date(values.startDate).toISOString());
        queryParams.append('endDate', new Date(values.endDate).toISOString());
      } else if (values.filter === 'Transactions') {
        queryParams.append('count', values.transactions?.toString());
      } else if (values.filter === 'Days') {
        let days;
        switch (values.noOfDays) {
          case noOfDaysOption[0]:
            days = '20';
            break;
          case noOfDaysOption[1]:
            days = '40';
            break;
          case noOfDaysOption[2]:
            days = '60';
            break;
          case noOfDaysOption[3]:
            days = '80';
            break;
          default:
            days = '20';
            break;
        }
        queryParams.append('noOfDays', days);
      }

      const response = await apiStatementReceipt(queryParams.toString(), currentAccount?._id || '');

      // Handle the response based on the file format
      let blob;
      let mimeType;
      if (values.format === StatementFormat.pdf) {
        blob = new Blob([response], { type: 'application/pdf' });
        mimeType = 'application/pdf';
      } else if (values.format === StatementFormat.csv) {
        blob = new Blob([response], { type: 'text/csv' });
        mimeType = 'text/csv';
      } else if (values.format === StatementFormat.xlsx) {
        blob = new Blob([response], { type: 'text/xlsx' });
        mimeType = 'text/xlsx';
      } else {
        throw new Error('Unsupported file format');
      }

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const hideLink = document.createElement('a');
      hideLink.style.display = 'none';
      hideLink.href = url;
      hideLink.download = `${currentAccount?.name || 'Statement of Account'}.${values.format}`;
      document.body.appendChild(hideLink);
      hideLink.click();
      document.body.removeChild(hideLink);
    } catch (error: any) {
      console.log(error);
      notifications.show({
        title: 'Download Failed',
        color: 'red',
        message: error.message || 'An error occurred, please try again later.',
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleSubmit = async (values: typeof form.values) => {
    if (form.isValid()) {
      handleDownload(values);
    } else {
      notifications.show({
        title: 'Form Error',
        color: 'red',
        message: 'Please fill all required fields correctly.',
      });
    }
  };

  return (
    <section className={`${style.statement} px-16 py-12`}>
      <h1 className="font-bold me-11 my-2 text-lg ">Bank Statement</h1>
      <div className="bg-[#ffffff] rounded-xl px-10 py-5 mb-5 min-h-[75vh] ">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid mb={0} grow>
            <Grid.Col span={5}>
              <Select
                ta="left"
                my={8}
                searchable
                label="Select account"
                type="text"
                disabled={isBankLoading}
                placeholder="Pick value"
                data={allAccount.map((account) => ({
                  value: account.number,
                  label: `${account.name} (${account.number})`,
                }))}
                {...form.getInputProps('originatorAccountNumber')}
              />
            </Grid.Col>
            <Grid.Col span={7}>
              <NumberInput
                ta="left"
                label="Available Balance"
                className="font-bold text-black"
                radius={5}
                thousandSeparator
                px={0}
                my={8}
                value={currentAccount?.balance?.available || 0}
                disabled
                // {...form.getInputProps('balance')}
              />
            </Grid.Col>
            {/* <Grid.Col span={6}>
              <NumberInput
                ta="left"
                label="Available Balance"
                className="font-bold text-black"
                radius={5}
                thousandSeparator
                px={0}
                my={8}
                value={currentAccount?.balance?.available || 0}
                disabled
                // {...form.getInputProps('balance')}
              />
            </Grid.Col> */}
            {/* <Grid.Col span={6}>
              <NumberInput
                ta="left"
                label="Ledger Balance"
                thousandSeparator
                className="font-bold text-black"
                radius={5}
                px={0}
                my={8}
                value={currentAccount?.balance?.ledger || 0}
                disabled
              />
            </Grid.Col> */}
            <Grid.Col span={4}>
              <Select
                ta="left"
                my={8}
                searchable
                label="Filter"
                withAsterisk
                type="text"
                placeholder="Pick value"
                data={Object.keys(StatementFilter).map((key, idx) => {
                  return { label: StatementFilter[key as StatementFilterKeys], value: key };
                })}
                {...form.getInputProps('filter')}
                defaultValue={'Date'}
                value={selectedFilter}
                onChange={(val) => {
                  const value = val as StatementFilterKeys;
                  if (value) {
                    setSelectedFilter(value);
                  }
                }}
              />
            </Grid.Col>

            {selectedFilter === ('Date' as StatementFilterKeys) && (
              <>
                <Grid.Col span={4}>
                  <DateInput
                    my={8}
                    clearable
                    withAsterisk
                    label="Start Date"
                    placeholder="Start Date"
                    {...form.getInputProps('startDate')}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <DateInput
                    my={8}
                    clearable
                    withAsterisk
                    label="End Date"
                    placeholder="End Date"
                    {...form.getInputProps('endDate')}
                  />
                </Grid.Col>
              </>
            )}

            {selectedFilter === ('Transactions' as StatementFilterKeys) && (
              <>
                <Grid.Col span={4}>
                  <TextInput
                    my={8}
                    withAsterisk
                    label="Generate Statement Only for this Number of Transactions"
                    placeholder="Pick a value"
                    {...form.getInputProps('transactions')}
                  />
                </Grid.Col>
              </>
            )}
            {selectedFilter === ('Days' as StatementFilterKeys) && (
              <>
                <Grid.Col span={4}>
                  <Select
                    my={8}
                    clearable
                    withAsterisk
                    label="Generate Statement for this Last Number of Days"
                    placeholder="Pick a value"
                    data={noOfDaysOption}
                    {...form.getInputProps('noOfDays')}
                  />
                </Grid.Col>
              </>
            )}
          </Grid>
          <Grid>
            <Grid.Col span={4}>
              <Select
                ta="left"
                my={8}
                searchable
                clearable
                label="Transaction Type"
                withAsterisk
                type="text"
                placeholder="Pick value"
                data={['All', 'Credit', 'Debit']}
                {...form.getInputProps('type')}
              />
            </Grid.Col>
            {/* <Grid.Col span={4}>
              <Select
                ta="left"
                my={8}
                searchable
                clearable
                label="File Format"
                withAsterisk
                type="text"
                placeholder="Pick value"
                data={[...Object.values(StatementFormat)]}
                {...form.getInputProps('format')}
              />
            </Grid.Col> */}
            {/* <Grid.Col span={4}></Grid.Col> */}
          </Grid>

          <Button
            variant="gradient"
            gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
            //  w={120}
            radius={6}
            mt={40}
            type="submit"
            disabled={loading}
            // onClick={() => console.log(form.values)}
          >
            {loading ? 'Generating Statement...' : 'Download Statement'}
          </Button>
        </form>
      </div>
    </section>
  );
};

export default Statement;
