import { Modal, Tabs } from '@mantine/core';
import { SetStateAction, useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import CsvStep1 from '../csv/CsvStep1';

type Prop = {
  opened: boolean;
  open: () => void;
  close: () => void;
};

export type StepType = {
  step: number;
  title: string;
};

const Index = ({ opened, close }: Prop) => {
  const [activeStep, setActiveStep] = useState(1);
  const [activeTab, setActiveTab] = useState<string | null>('online');
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);

  const onlineSteps = [
    { step: 1, title: 'Bulk Transfer' },
    { step: 2, title: 'Bulk Transfer' },
    { step: 3, title: 'Review details of your transfer' },
    { step: 4, title: '' },
    { step: 5, title: '' },
  ];

  const csvSteps = [
    { step: 1, title: 'CSV Upload' },
    { step: 2, title: 'CSV Upload' },
    { step: 3, title: 'Review CSV Details' },
    { step: 4, title: '' },
    { step: 5, title: '' },
  ];

  const steps = activeTab === 'online' ? onlineSteps : csvSteps;

  const nextStep = (nextbeneficiaries?: Beneficiary[]) => {
    if (nextbeneficiaries) {
      setBeneficiaries(nextbeneficiaries);
    }
    console.log(nextbeneficiaries);
    setActiveStep((current) => Math.min(current + 1, steps.length));
  };

  const prevStep = () => setActiveStep((current) => Math.max(current - 1, 1));

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleClose = () => {
    setActiveStep(1);
    close();
  };

  const getCurrentStepTitle = () => steps.find((s) => s.step === activeStep)?.title || '';

  const handleRemoveBeneficiary = (index: number) => {
    const updatedBeneficiaries = beneficiaries.filter((_, i) => i !== index);
    setBeneficiaries(updatedBeneficiaries);
  };

  // console.log('Beneficiaries in Step4:', beneficiaries);

  return (
    <>
      <Modal
        size={700}
        opened={opened}
        onClose={handleClose}
        closeOnClickOutside={false}
        title={getCurrentStepTitle()}
        centered>
        <Tabs value={activeTab} onChange={setActiveTab}>
          {activeStep === 1 && (
            <Tabs.List>
              <Tabs.Tab value="online">Fill Online</Tabs.Tab>
              <Tabs.Tab value="csv">CSV</Tabs.Tab>
            </Tabs.List>
          )}

          {activeStep === 1 && (
            <>
              {activeTab === 'online' && (
                <Step1
                  onNext={nextStep}
                  onStepChange={handleStepChange}
                  beneficiaries={beneficiaries}
                />
              )}
              {activeTab === 'csv' && (
                <CsvStep1
                  onNext={nextStep}
                  onStepChange={handleStepChange}
                  beneficiaries={beneficiaries}
                />
              )}
            </>
          )}

          {activeStep === 2 && (
            <Step2
              onPrev={prevStep}
              onNext={nextStep}
              beneficiaries={beneficiaries}
              removeBeneficiary={handleRemoveBeneficiary}
            />
          )}
          {activeStep === 3 && (
            <Step3 onPrev={prevStep} onNext={nextStep} beneficiaries={beneficiaries} />
          )}
          {activeStep === 4 && <Step4 />}
        </Tabs>
      </Modal>
    </>
  );
};

export default Index;
