import React, { useState, useEffect } from 'react';
import {
  Grid,
  Select,
  TextInput,
  Button,
  Textarea,
  Box,
  LoadingOverlay,
  NumberInput,
} from '@mantine/core';
import { yupResolver } from 'mantine-form-yup-resolver';
import { useForm } from '@mantine/form';
import * as yup from 'yup';
import { FaPlus } from 'react-icons/fa';
import { notifications } from '@mantine/notifications';
import {
  apiGetBanks,
  apiAllAccount,
  apiGetCategories,
  apiGetAccountName,
} from '../../../../../api/ApiServices';
import AddbeneficiariesTable from '../AddBeneficiariesTable';
import { MAX_TRANSFER_AMOUNT } from '../../constant';
import numeral from 'numeral';

type Prop = {
  onNext: (beneficiaries: Beneficiary[]) => void;
  beneficiaries: Beneficiary[];
  onStepChange: (step: number, title: string) => void;
};

const Step1 = ({ onNext, onStepChange, beneficiaries }: Prop) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isBankLoading, setIsBankLoading] = useState(false);
  const [localBeneficiaries, setLocalBeneficiaries] = useState<Beneficiary[]>(beneficiaries);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);
  const [accountName, setAccountName] = useState<string>('');
  const [bankCode, setBankCode] = useState<string>('');
  const [nameEnquiryRef, setNameEnquiryRef] = useState<string>('');

  const schema = yup.object().shape({
    bankName: yup.string().required('Bank Name is required'),
    category: yup.string().optional().nullable(),
    accountName: yup.string().required('Recipient name is required'),
    accountNumber: yup.string().required('Recipient account number is required'),
    amount: yup.string().required('Amount is required'),
    narration: yup.string().optional(),
    originatorAccountNumber: yup.string().required('Originator Account Number is required'),
  });

  const form = useForm({
    initialValues: {
      bankName: null,
      category: null,
      accountName: '',
      accountNumber: '',
      amount: '',
      narration: '',
      originatorAccountNumber: '',
      bankCode: bankCode,
      nameEnquiryRef: nameEnquiryRef,
    },
    validate: yupResolver(schema),
  });

  // remove beneficiaries
  const removeBeneficiary = (index: number) => {
    const beneficiariesDetails = [...localBeneficiaries];
    beneficiariesDetails.splice(index, 1);
    setLocalBeneficiaries(beneficiariesDetails);
  };

  // add beneficiaries
  const addbeneficiaries = (data: Beneficiary) => {
    const newBeneficiary = {
      ...data,
      bankCode: bankCode,
      nameEnquiryRef: nameEnquiryRef,
    };

    if (Number(newBeneficiary.amount) > MAX_TRANSFER_AMOUNT) {
      notifications.show({
        title: 'Transfer Limit',
        color: 'orange',
        message: `Exceeded transfer amount limit of ₦${numeral(MAX_TRANSFER_AMOUNT).format(
          '0,0.00'
        )}. Please reduce the amount and try again`,
      });
      return;
    }

    setLocalBeneficiaries([...localBeneficiaries, newBeneficiary]);
    form.reset();
    setAccountName('');
    setBankCode('');
    form.setFieldValue('bankName', null);
    form.setFieldValue('category', null);
    form.setValues({ originatorAccountNumber: allAccounts[0]?.number });
    // form.setFieldValue('originatorAccountNumber', '');
  };

  const handleNext = () => {
    onNext(localBeneficiaries);
  };

  // Fetch banks
  const fetchBanks = async () => {
    try {
      const resp = await apiGetBanks();
      setBanks(resp.data.banks);
    } catch (error) {
      setError('Failed to fetch banks');
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  // get all business accounts
  const getAllAccount = async () => {
    setIsBankLoading(true);
    try {
      const response = await apiAllAccount();
      setAllAccounts(response.data.accounts);
      if (response.data.accounts) {
        form.setValues({ originatorAccountNumber: response.data.accounts[0]?.number });
      }
      setIsBankLoading(false);
    } catch (error) {
      console.log(error);
      setIsBankLoading(false);
    }
  };

  useEffect(() => {
    getAllAccount();
  }, []);

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const resp = await apiGetCategories();
      setCategories(resp.data.categories);
    } catch (error) {
      setError('Failed to fetch categories');
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fetch account name based on bank and account number
  const fetchAccountName = async (bankCode: string, accountNumber: string) => {
    setIsLoading(true);
    setAccountName('');
    try {
      const payload = {
        bankCode: bankCode,
        accountNumber: accountNumber,
      };
      const resp = await apiGetAccountName(payload);
      setBankCode(bankCode);
      setAccountName(resp.data.accountName);
      console.log(resp);
      setNameEnquiryRef(resp.data.nameEnquiryRef),
        form.setFieldValue('accountName', resp.data.accountName);
    } catch (error: any) {
       notifications.show({
         title: 'Name Enquiry',
         color: 'red',
         message: error?.message || 'An error occurred, please try again later.',
       });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const selectedBank = banks.find((bank) => bank.name === form.values.bankName);
    const accountNumber = form.values.accountNumber;
    if (selectedBank && selectedBank.code && accountNumber.length === 10) {
      fetchAccountName(selectedBank.code, accountNumber);
    } else {
      setAccountName('');
      form.setFieldValue('accountName', '');
    }
  }, [form.values.bankName, form.values.accountNumber, banks]);

  return (
    <div>
      <Box pos="relative">
        <LoadingOverlay
          zIndex={1000}
          visible={isLoading}
          overlayProps={{ radius: 'sm', blur: 1 }}
        />
        <form
          onSubmit={form.onSubmit((values) => {
            addbeneficiaries(values);
          })}>
          <Grid mt={20} mb={30} grow>
            <Grid.Col span={6}>
              <Select
                ta="left"
                my={8}
                searchable
                label="Select account"
                withAsterisk
                type="text"
                disabled={isBankLoading}
                placeholder="Pick value"
                data={allAccounts?.map((account) => ({
                  value: account.number,
                  label: `${account.name} (${account.number})`,
                }))}
                {...form.getInputProps('originatorAccountNumber')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                ta="left"
                my={8}
                searchable
                label="Recipient bank"
                withAsterisk
                placeholder="Pick value"
                data={banks.map((bank) => ({ value: bank.name, label: bank.name }))}
                {...form.getInputProps('bankName')}
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <TextInput
                ta="left"
                label="Recipient account number"
                type="number"
                withAsterisk
                radius={5}
                px={0}
                my={8}
                placeholder=""
                {...form.getInputProps('accountNumber')}
                onChange={(event) => {
                  const regexp = /^\d+$/;
                  const numberValue = event.target.value;

                  // if it's not a number
                  if (!regexp.test(numberValue) && numberValue !== '') return;

                  form.setFieldValue('accountNumber', numberValue);

                  if (numberValue.length !== 10) {
                    setAccountName('');
                    form.setFieldValue('accountName', '');
                  }
                }}
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <TextInput
                ta="left"
                label="Recipient name"
                withAsterisk
                radius={5}
                px={0}
                my={8}
                value={accountName}
                disabled
                placeholder={isLoading ? 'Loading...' : ''}
                {...form.getInputProps('accountName')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <NumberInput
                ta="left"
                label="Amount"
                thousandSeparator=","
                withAsterisk
                radius={5}
                px={0}
                my={8}
                placeholder=" "
                {...form.getInputProps('amount')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                ta="left"
                my={8}
                searchable
                label="Payment category"
                placeholder="Pick value"
                data={categories.map((category) => ({
                  value: category.name,
                  label: category.name,
                }))}
                {...form.getInputProps('category')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Textarea
                ta="left"
                label="What are you sending money for?"
                withAsterisk
                radius={5}
                px={0}
                my={8}
                placeholder=""
                {...form.getInputProps('narration')}
              />
            </Grid.Col>
          </Grid>

          <Box ta="right">
            <Button
              variant="outline"
              size="sm"
              radius="xl"
              color="#0798D0"
              fw={500}
              type="submit"
              leftSection={<FaPlus size={14} />}>
              Add Beneficiary
            </Button>
          </Box>

          <AddbeneficiariesTable
            beneficiaries={localBeneficiaries}
            removeBeneficiary={removeBeneficiary}
          />

          <Grid justify="right" mt="xl">
            <Button
              variant="gradient"
              gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
              w={168}
              radius={12}
              mx={20}
              type="button"
              onClick={handleNext}
              disabled={isLoading || localBeneficiaries.length === 0}>
              Next
            </Button>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default Step1;
