import { Table, Tabs, Loader, Center } from '@mantine/core';
import { useEffect, useState, SetStateAction } from 'react';
import { apiTransaction } from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { useLocation, useNavigate } from 'react-router';
import RecieptModal from './component/receiptModal';
import { useDisclosure } from '@mantine/hooks';
import { getCBNTransferFee } from './component/modal/Step2';

const RecentTransactionTab = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [tab, setTab] = useState<string>('');
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [opened, { open, close }] = useDisclosure(false);

  type TransactionStatus = 'successful' | 'failed' | 'processing';

  const statusStyles: Record<TransactionStatus, React.CSSProperties> = {
    successful: { color: '#16ad16' },
    processing: { color: '#f79647' },
    failed: { color: 'red' },
  };

  const fetchTransactions = async (status: string | null) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiTransaction(status ?? '');
      setTransactions(response.data.transactions);
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.message || 'An error occurred, please try again later.',
        })
      );
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash.slice(1);

  useEffect(() => {
    if (hash) {
      setTab(hash);
    } else {
      setTab('all');
    }
  }, [location]);

  useEffect(() => {
    if (tab) {
      fetchTransactions(tab === 'all' ? null : tab);
    }
  }, [tab]);

  const handleReceipt = (transactionId: string) => {
    const selected = transactions.find((transaction) => transaction._id === transactionId);
    setSelectedTransaction(selected || null);
    open();
    console.log(selected, 'selected');
  };

  const handleTabChange = (newTab: SetStateAction<string | null>) => {
    setTab(newTab as string);
    navigate(`#${newTab}`, { replace: true });
  };

  const renderTable = () => {
    if (loading) {
      return (
        <Center>
          <Loader size={30} className="my-24" />
        </Center>
      );
    }

    if (transactions.length === 0) {
      return (
        <div className="text-center">
          <img
            src="/assets/dash/empty-state.png"
            alt="empty state"
            className="w-[17rem] mx-auto my-8"
          />
          <h2 className="font-semibold mt-1">No Data</h2>
          <p className="text-sm">Currently, there are no {tab} payments recorded.</p>
        </div>
      );
    }

    const rows = transactions.map((transaction) => (
      <Table.Tr
        key={transaction._id}
        onClick={() => handleReceipt(transaction._id)}
        className="cursor-pointer">
        <Table.Td style={statusStyles[transaction.status as TransactionStatus]}>
          {transaction.status}
        </Table.Td>
        <Table.Td>
          {transaction.createdAt
            ? dayjs(transaction.createdAt).format('ddd, MMM DD YYYY hh:mm A')
            : 'NA'}
        </Table.Td>
        <Table.Td>
          {numeral(
            transaction?.amount +
              getCBNTransferFee(transaction?.amount, transaction.beneficiaryBankCode) || 0
          ).format('0,0.00')}
        </Table.Td>
        <Table.Td>{transaction.narration}</Table.Td>
        <Table.Td>{transaction.type}</Table.Td>
        <Table.Td>{transaction.balance}</Table.Td>
      </Table.Tr>
    ));

    return (
      <Table striped highlightOnHover withRowBorders={false} stickyHeader stickyHeaderOffset={0}>
        <Table.Thead>
          <Table.Tr className="bg-[#F8F8F8]">
            <Table.Th>Status</Table.Th>
            <Table.Th>Date</Table.Th>
            <Table.Th>Amount(₦)</Table.Th>
            <Table.Th>Description</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th>Balance</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    );
  };

  return (
    <>
      <Tabs
        value={tab}
        mt={20}
        className="bg-[#ffffff] rounded-xl p-3 mb-10"
        onChange={handleTabChange}>
        <Tabs.List>
          <h1 className="font-medium text-sm me-10 my-auto">Recent Transactions</h1>
          <Tabs.Tab value="all">All</Tabs.Tab>
          <Tabs.Tab value="successful">Successful</Tabs.Tab>
          <Tabs.Tab value="processing">Processing</Tabs.Tab>
          <Tabs.Tab value="failed">Failed</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="all">
          <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
        </Tabs.Panel>

        <Tabs.Panel value="successful">
          <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
        </Tabs.Panel>

        <Tabs.Panel value="processing">
          <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
        </Tabs.Panel>

        <Tabs.Panel value="failed">
          <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
        </Tabs.Panel>
      </Tabs>

      <RecieptModal
        opened={opened}
        close={close}
        approvalData={selectedTransaction ? [selectedTransaction] : []}
        open={open}
      />
    </>
  );
};

export default RecentTransactionTab;
