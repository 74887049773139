import style from '../../styles/style.module.css';
import { GoHistory } from 'react-icons/go';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { IoSettingsOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { SlLogout } from 'react-icons/sl';
import { PiCopy } from 'react-icons/pi';
import { MdDashboard } from 'react-icons/md';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import { RiExchangeFundsLine } from 'react-icons/ri';
import { apiApprovalsCount, apiLogout } from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';

const SideBar = () => {
  const [error, setError] = useState('');
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const logout = async () => {
    try {
      const response = await apiLogout();
      navigate('/login');
      console.log(response);
    } catch (error) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        message: 'An error occurred, please try again later.',
      });
      setError('Unable to logout');
    }
  };
  const counts = async () => {
    try {
      const response = await apiApprovalsCount();
      setCount(response.data.noOfPendingApprovals);
      // console.log(response.data.noOfPendingApprovals, 'counts');
    } catch (error) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: 'An error occurred, please try again later.',
        })
      );
    }
  };
  useEffect(() => {
    counts();
  }, []);

  const sidebarItems = [
    { path: '/dashboard', label: 'Dashboard', icon: <MdDashboard className="my-auto" /> },
    {
      path: '/dashboard/funds-transfer',
      label: 'Funds Transfer',
      icon: <RiExchangeFundsLine className="my-auto" />,
    },
    {
      path: '/dashboard/transaction-history',
      label: 'Transaction History',
      icon: <GoHistory className="my-auto" />,
    },
    {
      path: '/dashboard/approvals',
      label: 'Approvals',
      icon: <IoMdNotificationsOutline className="my-auto" />,
      count:
        count > 0 ? (
          <div className="rounded-full bg-red-500 min-w-5 min-h-5 flex justify-center items-center text-white text-[0.55rem] animate-ping-short font-semibold">
            {count}
          </div>
        ) : null,
    },
    {
      path: '/dashboard/bank-statement',
      label: 'Bank Statement',
      icon: <PiCopy className="my-auto" />,
    },
    {
      path: '/dashboard/support',
      label: 'Contact Support',
      icon: <RxQuestionMarkCircled className="my-auto" />,
    },
    {
      path: '/dashboard/settings',
      label: 'Settings',
      icon: <IoSettingsOutline className="my-auto" />,
    },
  ];

  return (
    <>
      <section className={`${style.sidebar} shadow-lg`}>
        <div className="grid grid-rows-8 grid-flow-col gap-4 px-8 mt-4">
          {sidebarItems.map((item, index) => (
            <div
              key={index}
              className={`flex gap-2 ${
                location.pathname === item.path ? 'bg-[#0798D0] p-2 rounded-lg text-white' : ''
              }`}>
              {item.icon}
              <Link to={item.path} className="my-auto">
                <div className="flex justify-between items-center gap-3">
                  <span>{item.label}</span>
                  {item.count}
                </div>
              </Link>
            </div>
          ))}

          <div className="flex gap-2 row-start-10">
            <SlLogout className="my-auto" />
            <p className="cursor-pointer" onClick={logout}>
              Log out
            </p>
          </div>

          {/* <div className="flex gap-2">
            <SlQuestion className="my-auto" />
            <Link to="/dashboard/support">Support</Link>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default SideBar;
